// AuthContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import useJwt from '../Auth/jwt/useJwt';
import jwtDefaultConfig from '../Auth/jwt/jwtDefaultConfig';
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [shouldRefetch, setShouldRefetch] = useState(false);
    const token = useJwt.getToken();

    const logout = () => {
        setIsAuthenticated(false);
        localStorage.removeItem(jwtDefaultConfig.storageTokenKeyName);
    };

    useEffect(() => {
        if (token) {
            getProfile();
        }
    }, [shouldRefetch, token]);

    const getProfile = async () => {
        try {
            const res = await useJwt.getProfile();
            const profileData = res?.data?.data;
            const rememberme = localStorage.getItem(jwtDefaultConfig.rememberme);
            if (profileData) useJwt.setUserData(profileData, rememberme);
        } catch (error) {
            console.log(error?.response || 'Something went wrong');
        }
    };

    const isLoggedIn = () => {
        return token != null;
    };

    return (
        <AuthContext.Provider
            value={{
                isAuthenticated,
                logout,
                isLoggedIn,
                getProfile,
                setShouldRefetch,
                shouldRefetch
            }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
