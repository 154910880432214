// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import useJwt from '../Auth/jwt/useJwt';
import { fetchData } from '../utility/utils';

export const getQuestions = createAsyncThunk('questions/getQuestions', async () => {
    try {
        const response = await useJwt.getQuestions();
        return {
            data: response?.data?.data
        };
    } catch (error) {
        return {
            error: error?.response?.data?.message || 'Something went wrong'
        };
    }
});

export const getSixtyQuestions = createAsyncThunk('questions/getSixtyQuestions' , async () => {
    try {
         const response = await fetchData(`/ws/mnm/interestprofiler/questions?start=0&end=60`); 
         return {
            data: response?.data
        };
    } catch (error) {
        return {
            error: error?.response?.data?.message || 'Something went wrong'
        };
    }
} ) 

export const getThirtyQuestions = createAsyncThunk('questions/getThirtyQuestions' , async () => {
    try {
         const response = await fetchData(`/ws/mnm/interestprofiler/questions_30?start=0&end=30`); 
         return {
            data: response?.data
        };
    } catch (error) {
        return {
            error: error?.response?.data?.message || 'Something went wrong'
        };
    }
} ) 

export const questionsSlice = createSlice({
    name: 'questions',
    initialState: {
        loading: false,
        questionsData: {},
        questionsLength : 0,
        questionsList:{
            loading : false,
            data : {}
        },
        error: ''
    },
    reducers: {
        resetQuestionsData : (state) => {
            state.questionsData = {},
            state.questionsLength = 0,
            state.questionsList = {
                loading : false,
                data : {}
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSixtyQuestions.pending, (state) => {
                state.questionsList.loading = true;
                state.questionsList.data = {};
                state.questionsLength = 0; 
                state.error = '';
            })
            .addCase(getSixtyQuestions.fulfilled, (state, action) => {
                state.questionsList.loading = false;
                state.questionsList.data = action.payload.data;
                state.questionsLength = 60;
                state.error = '';
            })
            .addCase(getThirtyQuestions.pending, (state) => {
                state.questionsList.loading = true;
                state.questionsList.data = {};
                state.questionsLength = 0;
                state.error = '';
            })
            .addCase(getThirtyQuestions.fulfilled, (state, action) => {
                state.questionsList.loading = false;
                state.questionsList.data = action.payload.data;
                state.questionsLength = 30;
                state.error = '';
            })
            .addCase(getQuestions.pending, (state) => {
                state.loading = true;
                state.questionsData = {};
                state.error = '';
            })
            .addCase(getQuestions.fulfilled, (state, action) => {
                state.loading = false;
                state.questionsData = action.payload.data;
                state.error = '';
            })
            .addCase(getQuestions.rejected, (state, action) => {
                state.loading = false;
                state.questionsData = {};
                state.error = action.payload.error;
            });
    }
});

export const {resetQuestionsData} = questionsSlice.actions
export default questionsSlice.reducer;
