import axios from 'axios';

const baseUrl = process.env.REACT_APP_ONET_API_URL;
export const firstQuery = `?client=${process.env.REACT_APP_ONET_USERNAME}`;

// Create an Axios instance
export const apiInstance = axios.create({
    baseURL: process.env.REACT_APP_ONET_API_URL, // Replace with your API's base URL
    timeout: 10000 // Set a timeout if needed
});

// Add a request interceptor to append query to params
apiInstance.interceptors.request.use(
    (config) => {
        // Modify the config before the request is sent
        // Assuming you want to append a query, e.g., 'search=term'
        const additionalParams = { client: process.env.REACT_APP_ONET_USERNAME }; // Modify as needed

        // Merge additional params with existing ones
        config.params = { ...config.params, ...additionalParams };

        return config;
    },
    (error) => {
        // Handle request error here
        return Promise.reject(error);
    }
);

export default {
    searchCareers: `${baseUrl}/ws/mnm/search${firstQuery}`,
    browseCareerByIndustry: `${baseUrl}/ws/mnm/browse${firstQuery}`,
    summary: `${baseUrl}/ws/mnm/careers`
};
