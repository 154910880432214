export const CareerGroupsTabs = {
    Careerbag: 'Careerbag',
    BrightOutlook: 'BrightOutlook',
    Careercluster: 'Careercluster',
    CareerSorted: 'CareerSorted',
    JobPrepration: 'JobPrepration',
    SearchNow: 'SearchNow'
};

export const RateType = {
    Hourly: 'Hourly',
    Annual: 'Annual'
};

export const SortDirections = {
    Descending: 'Descending',
    Ascending: 'Ascending'
};

export const TrainingFilter = {
    ShowAll: 'ShowAll',
    ShowRecentGraduates: 'ShowRecentGraduates'
};

export const ClustersData = {
    '1.0000': {
        url: 'agriculture-food-and-natural-resources-overview',
        description:
            'The production, processing, marketing, distribution, financing, and development of agricultural commodities and resources including food, fiber, wood products, natural resources, horticulture, and other plant and animal products or resources.'
    },
    '2.0000': {
        url: 'construction-overview',
        description: 'Designing, planning, managing, building and maintaining the built environment.'
    },
    '3.0000': {
        url: 'arts-av-technology-and-communications-overview',
        description:
            'Designing, producing, exhibiting, performing, writing, and publishing multimedia content including visual and performing arts and design, journalism and entertainment services.'
    },
    '4.0000': {
        url: 'business-management-and-administration-overview',
        description:
            'Planning, organizing, directing and evaluating business functions essential to efficient and productive business operations.'
    },
    '5.0000': {
        url: 'education-and-training-overview',
        description:
            'Planning, managing and providing education and training services, and related learning support services.'
    },
    '6.0000': {
        url: 'finance-and-insurance-overview',
        description:
            'Planning, services for financial and investment planning, banking, insurance, and business financial management.'
    },
    '7.0000': {
        url: 'government-and-public-administration-overview',
        description:
            'Planning and performing government functions at the local, state and federal levels, including governance, national security, foreign service, planning, revenue and taxation, and regulations.'
    },
    '8.0000': {
        url: 'healthcare-overview',
        description:
            'Planning, managing, and providing therapeutic services, diagnostic services, health informatics, support services, and biotechnology research and development.'
    },
    '9.0000': {
        url: 'hospitality-and-tourism-overview',
        description:
            'Management, marketing and operations of restaurants and other food services, lodging, attractions, recreation events and travel related services.'
    },
    '10.0000': {
        url: 'human-services-overview',
        description:
            'Preparing individuals for employment in careers that relate to families and human needs such as counseling and mental health services, family and community services, personal care and consumer services.'
    },
    '11.0000': {
        url: 'information-technology-overview',
        description:
            'The design, development, support and management of hardware, software, multimedia and systems integration services.'
    },
    '12.0000': {
        url: 'law-public-safety-and-corrections-overview',
        description:
            'Planning, managing, and providing legal, public safety and protective services and homeland security, including professional and technical support services.'
    },
    '13.0000': {
        url: 'manufacturing-overview',
        description:
            'Planning, managing and performing the processing of materials into intermediate or final products and related professional and technical support activities such as production planning and control, maintenance and manufacturing, and process engineering.'
    },
    '14.0000': {
        url: 'marketing-overview',
        description:
            'This cluster covers careers in planning, managing, and performing marketing activities to reach organizational objectives.'
    },
    '15.0000': {
        url: 'stem-overview',
        description:
            'Planning, managing and providing scientific research and professional and technical services (e.g., physical science, social science, engineering) including laboratory and testing services, and research and development services.'
    },
    '16.0000': {
        url: 'transportation-distribution-and-logistics-overview',
        description:
            'Planning, management, and movement of people, materials, and goods by road, pipeline, air, rail and water and related professional support services such as transportation infrastructure planning and management, logistics services, mobile equipment and facility maintenance.'
    }
};

export const CareerOneStopApiKey = process.env.REACT_APP_ONET_TOKEN_KEY; // careerOneStopApi key
export const CareerOneStopApiUrl = process.env.REACT_APP_CAREER_ONE_STOP_API; // careerOneStop api url
export const CareerOneStopUserId = process.env.REACT_APP_USER_ID;


export const logoColors = {
    red:'#800020',
    yellow:'#D4AF37',
    green:'#047B69',
    gray:'#F5F5DC',
    all :'all'
}


export const HowWeSolveProblemsSteps = {
    interviews : {
        id:1,
        title : 'Interviews',
        text : "Dive into the world of various professions with SOS's unique interview series. We bring you engaging, coffee chat-style discussions with distinguished professionals from diverse fields. These interviews offer students a rare glimpse into the realities of different careers, providing personal insights, experiences, and advice. Our aim is to help students understand the nuances of various professions, guiding them to make informed decisions about their future career paths. Join us to gain an insider's perspective and expand your career horizons."
    },
    articles : {
        id:2,
        title : 'Articles',
        text : "Dive into the world of various professions with SOS's unique interview series. We bring you engaging, coffee chat-style discussions with distinguished professionals from diverse fields. These interviews offer students a rare glimpse into the realities of different careers, providing personal insights, experiences, and advice. Our aim is to help students understand the nuances of various professions, guiding them to make informed decisions about their future career paths. Join us to gain an insider's perspective and expand your career horizons."
    },
    communityPlusMentors : {
        id:3,
        title : 'Community + Mentors',
        text : "Dive into the world of various professions with SOS's unique interview series. We bring you engaging, coffee chat-style discussions with distinguished professionals from diverse fields. These interviews offer students a rare glimpse into the realities of different careers, providing personal insights, experiences, and advice. Our aim is to help students understand the nuances of various professions, guiding them to make informed decisions about their future career paths. Join us to gain an insider's perspective and expand your career horizons."
    },
    certificatesAcheivementAwards : {
        id:4,
        title : 'Certificates and achievement awards',
        text : "Dive into the world of various professions with SOS's unique interview series. We bring you engaging, coffee chat-style discussions with distinguished professionals from diverse fields. These interviews offer students a rare glimpse into the realities of different careers, providing personal insights, experiences, and advice. Our aim is to help students understand the nuances of various professions, guiding them to make informed decisions about their future career paths. Join us to gain an insider's perspective and expand your career horizons."
    },
    scholarships : {
        id:5,
        title : 'scholshrips',
        text : "Dive into the world of various professions with SOS's unique interview series. We bring you engaging, coffee chat-style discussions with distinguished professionals from diverse fields. These interviews offer students a rare glimpse into the realities of different careers, providing personal insights, experiences, and advice. Our aim is to help students understand the nuances of various professions, guiding them to make informed decisions about their future career paths. Join us to gain an insider's perspective and expand your career horizons."
    },
    personlizedCareerAssistant : {
        id:6,
        title : 'personalized AI Career Assistant',
        text : "Dive into the world of various professions with SOS's unique interview series. We bring you engaging, coffee chat-style discussions with distinguished professionals from diverse fields. These interviews offer students a rare glimpse into the realities of different careers, providing personal insights, experiences, and advice. Our aim is to help students understand the nuances of various professions, guiding them to make informed decisions about their future career paths. Join us to gain an insider's perspective and expand your career horizons."
    },
    internshipVolunteer : {
        id:7,
        title : 'Internship/Volunteer',
        text : "Dive into the world of various professions with SOS's unique interview series. We bring you engaging, coffee chat-style discussions with distinguished professionals from diverse fields. These interviews offer students a rare glimpse into the realities of different careers, providing personal insights, experiences, and advice. Our aim is to help students understand the nuances of various professions, guiding them to make informed decisions about their future career paths. Join us to gain an insider's perspective and expand your career horizons."
    }
}


export const FAQsData   = [
    {
        id: 1,
        "title": "What are the benefits of being a Team member of the SOS organization?",
        "details": [
            "Networking with Industry Leaders: Opportunities to connect with professionals across various industries.",
            "Career Insight and Guidance: Engage in workshops that provide a deeper understanding of career paths.",
            "Internship and Scholarship Opportunities: Access to exclusive internship and scholarship openings.",
            "Volunteering Certificates and Recognition: Certificates to add value to your resume.",
            "Skill Development: Enhance skills like project management, collaboration, and problem-solving."
        ]
    },
    {
        id: 2,
        "title": "How can I join SOS?",
        "details": [
            "Educational Outreach: Programs designed to promote education in underprivileged communities.",
            "Environmental Initiatives: Projects aimed at protecting and preserving the environment.",
            "Health and Wellness Campaigns: Organizing health camps and wellness drives for marginalized groups.",
            "Technology and Innovation: Initiatives that use technology to solve social issues.",
            "Community Empowerment: Empowering local communities through training and skill development."
        ]
    },
    {
        id: 3,
        "title": "What kind of events or activities does SOS organize?",
        "details": [
            "Sign up on the website: Register via the official SOS platform.",
            "Attend Orientation: Attend a mandatory orientation session for new volunteers.",
            "Select a Project: Choose from a list of ongoing projects where your skills are needed.",
            "Submit Availability: Provide your availability schedule to match with project timelines.",
            "Receive Volunteer Kit: Get materials and guidance to assist you in your volunteer role."
        ]
    },
    {
        id: 4,
        "title": "How can I stay updated about upcoming SOS events and activities?",
        "details": [
            "Communication Skills: Effective communication is key in working with communities.",
            "Leadership: Ability to take initiative and lead smaller project groups.",
            "Technical Proficiency: Familiarity with relevant tools and technologies.",
            "Collaboration: Work efficiently within diverse teams.",
            "Problem-Solving: Address real-world challenges with innovative solutions."
        ]
    } 
]
export const QuestionTypes = {
    ThirtyQuestions : 30,
    SixtyQuestions : 60
}
export const ProfilerSteps = {
    enterScores : -1,
    welcome : 0,
    howItWorks : 1,
    notATest : 2,
    questionList1 : 3,
    questionList2 : 4,
    questionList3 : 5,
    questionList4 : 6,
    questionList5 : 7,
    greatJob : 8,
    profilerResults : 9,
    interestPlusJobZone : 10,
    currentOrFuture : 11,
    exploreJobZones : 12,
    selectJobZone : 13,
    interestPlusJobZoneResult : 14,
    careersByInterests: 15,
    findMoreCareers: 16,
    careersByInterestArea: 17
}

export const ProfilerPrintPagesParams = {
  'unsure':'unsure',
  'interest-areas':'interest-areas',
  'job-zones':'job-zones',
  'interest-wise-careers':'interest-wise-careers' 
}

export const LocalStorageKeyNames = {
   'mcqAnswers' : 'mcqAnswers',
   'sixtyQuestions':'sixtyQuestions',
   'thirtyQuestions':'thirtyQuestions',
   'selectedQuestionsType':'selectedQuestionsType'
}
 
export const JobZones = [
    { "id": 1, "label": "Little or no" },
    { "id": 2, "label": "Some"  },
    { "id": 3, "label": "Medium" },
    { "id": 4, "label": "High"  },
    { "id": 5, "label": "Extensive"  }
  ]

export const NumbersSpellings = [
    'Zero',
    'One',
    'Two',
    'Three',
    'Four',
    'Five'
]
export const InterestAreasData = {
    Realistic: {
      description: 'People with Realistic interests like work that includes practical, hands-on problems and answers. Often people with Realistic interests do not like careers that involve paperwork or working closely with others.',
      list: [
        'Working with plants and animals',
        'Real-world materials like wood, tools, and machinery',
        'Outside work'
      ]
    },
    Investigative: {
      description: 'People with Investigative interests like work that has to do with ideas and thinking rather than physical activity or leading people.',
      list: [ 
        'Searching for facts',
        'Figuring out problems'
      ]
    }, 
    Artistic: {
      description: 'People with Artistic interests like work that deals with the artistic side of things, such as acting, music, art, and design.',
      list: [ 
        'Creativity in their work',
        'Work that can be done without following a set of rules'
      ]
    },
    Social: {
      description: 'People with Social interests like working with others to help them learn and grow. They like working with people more than working with objects, machines, or information.',
      list: [
        'Teaching',
        'Giving advice',
        'Helping and being of service to people'
      ]
    },
    Enterprising: {
      description: 'People with Enterprising interests like work that has to do with starting up and carrying out business projects. These people like taking action rather than thinking about things.',
      list: [
        'Persuading and leading people',
        'Making decisions',
        'Taking risks for profits'
      ]
    },
    Conventional: {
      description: 'People with Conventional interests like work that follows set procedures and routines. They prefer working with information and paying attention to details rather than working with ideas.',
      list: [
        'Working with clear rules', 
        'Following a strong leader'
      ]
    }
  }
export const InterestAreas = { 'Realistic' : ['bg-[#03AC3A]','text-[#03AC3A]'], 'Investigative': ['bg-[#FF0086]','text-[#FF0086]'], 'Artistic': ['bg-[#833636]','text-[#833636]'], 'Social': ['bg-[#670FEA]','text-[#670FEA]'], 'Enterprising': ['bg-[#FF9125]','text-[#FF9125]'], 'Conventional': ['bg-[#02D3C9]','text-[#02D3C9]'] }

export const carouselSettings = {
    dots: false,             
    infinite: false,          
    speed: 3000,            
    slidesToShow: 3,       
    slidesToScroll: 1,      
    autoplay: false,        
    autoplaySpeed: 3000,    
    cssEase: "linear",       
    arrows: false,           
  
    responsive: [
      {
        breakpoint: 1024,    
        settings: {
          slidesToShow: 3,   
        }
      },
      {
        breakpoint: 768,    
        settings: {
          slidesToShow: 2,   
        }
      },
      {
        breakpoint: 480,     
        settings: {
          slidesToShow: 1,   
        }
      }
    ]
  };
  


  export const ParallexSpeed  = {
    xl : {
      Empowering : 0.2,
      Academic : 0.2,
      para : 0.15,
      para2 : 0.05,
      Enhancing : 0.1, 
      ProgressThrough : 0.1, 
      Customized : 0.1,
      Enriching: 0.1,
      Students : 0.15, 
      circle:0.1
    },
    lg : {
      Empowering : 0.2,
      Academic : 0.2,
      para : 0.15,
      para2 : 0.05,
      Enhancing : 0.1, 
      ProgressThrough : 0.1, 
      Customized : 0.1,
      Enriching: 0.1,
      Students : 0.15, 
      circle:0.1
    },
    md : {
      Empowering : 0.2,
      Academic : 0.05,
      para : 0.15,
      para2 : 0.05,
      Enhancing : 0.1, 
      ProgressThrough : 0.1, 
      Customized : 0.1,
      Enriching: 0.1,
      Students : 0.15, 
      circle:0.1
    },
    sm : {
      Empowering : 0.2,
      Academic : 0.05,
      para : 0.15,
      para2 : 0.05,
      Enhancing : 0.1, 
      ProgressThrough : 0.1, 
      Customized : 0.1,
      Enriching: 0.1,
      Students : 0.05, 
      circle:0.1
    },
    xs:{
      Empowering : 0.2,
      Academic : 0.05,
      para : 0.15,
      para2 : 0.05,
      Enhancing : 0.1, 
      ProgressThrough : 0.1, 
      Customized : 0.1,
      Enriching: 0.1,
      Students : 0.05, 
      circle:0.1
    }
  }
  export const ParallexPositions = {
     xl : {
      Empowering : -140,
      Academic : 140,
      para : 250,
      para2 : 100,
      Enhancing :-140, 
      ProgressThrough : -100, 
      Customized : 0.1,
      Enriching: -140,
      Students : -350, 
      circle:0
     },
     lg : {
      Empowering : -40,
      Academic : 150,
      para : 250,
      para2 : 100,
      Enhancing : -40, 
      ProgressThrough : 150, 
      Customized : 0.1,
      Enriching: -40,
      Students : -300, 
      circle:0
     },
     md : {
      Empowering : 0,
      Academic : 80,
      para : 250,
      para2 : 100,
      Enhancing : 0, 
      ProgressThrough : 100, 
      Customized : 0.1,
      Enriching: 0,
      Students : -200, 
      circle:0
     },
     sm : {
      Empowering : 40,
      Academic : 60,
      para : 250,
      para2 : 40,
      Enhancing : 40, 
      ProgressThrough : 100, 
      Customized : 0.1,
      Enriching: 0,
      Students : 0, 
      circle:0
     },
     xs:{
      Empowering : 0,
      Academic : 20,
      para : 100,
      para2 : 40,
      Enhancing : 0, 
      ProgressThrough : 100, 
      Customized : 0.1,
      Enriching: -40,
      Students : -20,
      circle:0
     }
  }