// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchData } from '../utility/utils';

export const getCareerSummary = createAsyncThunk('summary/getCareerSummary', async (code) => {
    try {
        const { data } = await fetchData(`/ws/mnm/careers/${code}/report`);
        if (Object.keys(data).length > 0) {
            return {
                data
            };
        } else return { data: {} };
    } catch (error) {
        return {
            error: error?.response?.data?.message || 'Something went wrong'
        };
    }
});

export const getCareerOverview = createAsyncThunk('summary/getCareerOverview', async (code) => {
    try {
        const { data } = await fetchData(`/ws/mnm/careers/${code}`);
        if (Object.keys(data).length > 0) {
            return {
                data
            };
        } else return { data: {} };
    } catch (error) {
        return {
            error: error?.response?.data?.message || 'Something went wrong'
        };
    }
});

export const Summary = createSlice({
    name: 'summary',
    initialState: {
        loading: false,
        summary: {},
        error: ''
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getCareerSummary.pending, (state) => {
                state.loading = true;
                state.summary = {};
                state.error = '';
            })
            .addCase(getCareerSummary.fulfilled, (state, action) => {
                state.loading = false;
                state.summary = action.payload.data;
                state.error = '';
            })
            .addCase(getCareerSummary.rejected, (state, action) => {
                state.loading = false;
                state.summary = {};
                state.error = action.payload.error;
            })
            .addCase(getCareerOverview.pending, (state) => {
                state.loading = true;
                state.error = '';
            })
            .addCase(getCareerOverview.fulfilled, (state, action) => {
                state.loading = false;
                state.summary = { ...state.summary, career: action.payload.data };
                state.error = '';
            })
            .addCase(getCareerOverview.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.error;
            });
    }
});

export default Summary.reducer;
