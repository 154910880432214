import React, { Suspense, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './Contexts/AuthContext';
import { store } from './redux/store';
import { Provider } from 'react-redux';
import AppRoutes from './Routes/Router';
import Loader from './components/Loader';
import './App.css';
import './blackScreen.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles


const App = () => {

    useEffect(() => {
        AOS.init({
          duration: 1000,  
          offset: 100, // Offset from the original trigger point
          easing: 'ease-in-out', // Default easing for AOS animations
          once: true, // Whether animation should happen only once or every time you scroll up/down
        });
      }, []);

    return (
        <Provider store={store}>
            <AuthProvider>
                <BrowserRouter>
                    <Suspense fallback={<Loader />}>
                        <AppRoutes />
                    </Suspense>
                </BrowserRouter>
            </AuthProvider>
        </Provider>
    );
};

export default App;
