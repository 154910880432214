import React, { lazy } from 'react';

const Login = lazy(() => import('../pages/Auth/Login'));
const PasswordReset = lazy(() => import('../pages/Auth/PasswordReset'));
const SignUp = lazy(() => import('../pages/Auth/SignUp'));
const ResetPasswordFormScreen = lazy(() => import('../pages/Auth/ResetPasswordFormScreen'));
const VerifyEmail = lazy(() => import('../pages/Auth/Verifymail'));

export default [
    { path: '/login', element: <Login /> },
    { path: '/signup', element: <SignUp /> },
    { path: '/passwordreset', element: <PasswordReset /> },
    { path: '/verify-email', element: <VerifyEmail /> },
    { path: '/reset-password', element: <ResetPasswordFormScreen /> }
];
