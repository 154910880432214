import React from 'react';
import { Link } from 'react-router-dom';
import { FaLogo } from '../images/svg';
import { ChevronLeft } from '@mui/icons-material';

const PageNotFound = () => {
    return (
        <div className="min-h-screen flex items-center flex-col gap-2 justify-center bg-gray-100">
            <div className="flex justify-center items-center">
                <FaLogo />
            </div>
            <div className="gap-1 text-center flex items-center flex-col">
                <h1 className="text-4xl font-bold text-gray-800 mb-4">Page not found</h1>
                <Link to="/">
                    {' '}
                    <ChevronLeft fontSize="16" /> Go to Home{' '}
                </Link>
            </div>
        </div>
    );
};

export default PageNotFound;
