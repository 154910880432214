import React from 'react';

const Loader = ({ style }) => {
    return (
        <div className={`${style || 'min-h-screen'} flex justify-center items-center m-auto`}>
            <div className="loader p-5 rounded-full flex space-x-3">
                <div className="w-8 h-8 bg-primary-green rounded-full animate-bounce"></div>
                <div className="w-8 h-8 bg-primary-green rounded-full animate-bounce"></div>
                <div className="w-8 h-8 bg-primary-green rounded-full animate-bounce"></div>
            </div>
        </div>
    );
};

export default Loader;
