import React, { lazy } from 'react'; 

// Lazy import the components
const Overview = lazy(() => import('../pages/Dashboard/Overview')); 
const Resources = lazy(() => import('../pages/Dashboard/Resources'));
const Settings = lazy(() => import('../pages/Dashboard/Settings'));
const Summary = lazy(() => import('../pages/Dashboard/Summary'));
const SearchCareer = lazy(() => import('../pages/Dashboard/Find/SearchCareer'));
const BrowseCareer = lazy(() => import('../pages/Dashboard/Find/BrowseCareer'));
const LocalTraining = lazy(() => import('../pages/Dashboard/Profile/LocalTraining'));
const Certifications = lazy(() => import('../pages/Dashboard/Profile/Certifications'));
const Licenses = lazy(() => import('../pages/Dashboard/Profile/Licenses'));
const State = lazy(() => import('../pages/Dashboard/Profile/State'));
const Bright = lazy(() => import('../pages/Dashboard/Find/Bright'));
const CareerCluster = lazy(() => import('../pages/Dashboard/Find/CareerCluster'));
const Zone = lazy(() => import('../pages/Dashboard/Find/Zone'));
const LocalJobs = lazy(() => import('../pages/Dashboard/Profile/LocalJobs'));
const LocalSalary = lazy(() => import('../pages/Dashboard/Profile/LocalSalary'));
const SortedByInterest = lazy(() => import('../pages/Dashboard/Find/SortedByInterest'));
const WelcomePage = lazy(() => import('../pages/WelcomePage'));
const Profiler = lazy(() => import('../pages/Profiler'))
const PrintProfiler = lazy(() => import('../pages/Profiler/PrintProfiler'))
const ShareProfilerPage = lazy(() => import('../pages/Profiler/PrintProfiler/ShareProfilerPage'))

export default [
    { path: 'welcome', element: <WelcomePage /> },
    { path: '/overview', element: <Overview /> }, 
    { path: '/dashboard/resources', element: <Resources /> },
    { path: '/settings', element: <Settings /> },  
    { path: '/profile/summary/:code', element: <Summary /> },
    { path: '/find/browse', element: <BrowseCareer /> },
    { path: '/find/search', element: <SearchCareer /> },
    { path: '/profile/localtraining/:code', element: <LocalTraining /> },
    { path: '/find/bright', element: <Bright /> },
    { path: '/find/zone', element: <Zone /> },
    { path: '/find/sorted-by-interest', element: <SortedByInterest /> },
    { path: '/profile/certifications/:code', element: <Certifications /> },
    { path: '/profile/state/:code', element: <State /> },
    { path: '/profile/local-jobs/:code', element: <LocalJobs /> },
    { path: '/profile/local-salary/:code', element: <LocalSalary /> },
    { path: '/profile/licenses/:code', element: <Licenses /> },
    { path: '/find/cluster', element: <CareerCluster /> },
    { path: '/profiler', element: <Profiler /> },
    { path : '/print/:type', element : <PrintProfiler/> },
    { path : '/share/:type', element : <ShareProfilerPage/> }
];
