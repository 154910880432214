import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import useJwt from '../Auth/jwt/useJwt';

const PrivateLayout = () => {
    const token = useJwt.getToken();
    if (!token) {
        return <Navigate to="/login" />;
    }

    return <Outlet />;
};

export default PrivateLayout;
