import React from 'react';
import { useRoutes } from 'react-router-dom';
import authRoutes from './Auth';
import PublicRoutes from './PublicRoutes';
import PrivateRoutes from './PrivateRoutes';
import PrivateLayout from '../Layouts/PrivateLayout';
import AuthLayout from '../Layouts/AuthLayout';
import PageNotFound from '../pages/PageNotFound';
const AppRoutes = () => {
    const router = useRoutes([
        ...PublicRoutes,
        {
            path: '/',
            element: <AuthLayout />,
            children: [...authRoutes]
        },
        {
            path: '/',
            element: <PrivateLayout />,
            children: [...PrivateRoutes]
        },
        {
            path: '*',
            element: <PageNotFound />
        }
    ]);
    return router;
};

export default AppRoutes;
