import React, { lazy } from 'react';   
const ComingSoon = lazy(() => import('../pages/ComingSoon'));
const Contact = lazy(() => import('../pages/Dashboard/Contact'));
const Home = lazy(() => import('../pages/BlackPages/Home'));
const AboutUs = lazy(() => import('../pages/BlackPages/AboutUs'));
const HelpSos = lazy(() => import('../pages/BlackPages/HelpSos'));
const Career = lazy(() => import('../pages/BlackPages/Career'));
const FAQPage = lazy(() => import('../pages/BlackPages/FAQPage'));
const GetInTouch = lazy(() => import('../pages/BlackPages/GetInTouch'));

export default [
    { path: '/', element: <Home /> },
    { path : '/about-us' , element : <AboutUs/> },
    { path : '/faqs' , element : <FAQPage/> },
    { path : '/career' , element : <Career/> },
    { path : '/get-in-touch' , element : <GetInTouch/> },
    { path : '/help-sos' , element : <HelpSos/> },
    { path: 'coming-soon', element: <ComingSoon /> },
    { path: 'contact', element: <Contact /> }
];
