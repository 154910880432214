// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'; 
import { fetchData } from '../utility/utils';
 

export const getResult = createAsyncThunk('profiler/getResult' , async ({answerString, isManually}) => { 
    try {
         const response = await fetchData(`/ws/mnm/interestprofiler/results?answers=${answerString}`); 
         return {
            data: response?.data?.result || [],
            isManually:isManually
        };
    } catch (error) {
        return {
            error: error?.response?.data?.message || 'Something went wrong'
        };
    }
} ) 

export const getJobZones = createAsyncThunk('profiler/getJobZones', async ()=> {
    try{
        const response = await fetchData('/ws/mnm/interestprofiler/job_zones')
        const data = response?.data?.job_zone || ''
        return {
            data : data
        }
    } catch(error){
        console.log(error)
        return {
            error : error?.response?.data?.message || "Something went wrong"
        }
    }
})
export const profilerSlice = createSlice({
    name: 'profiler',
    initialState: {
        scores : null,
        scoreLoading:false,
        manually : false, 
        jobZonesData :{
            loading : false,
            data : []
        }
    },
    reducers: {
        enterScores : (state, action) => { 
            state.scores = action.payload.data
            state.manually  = action.payload.isManually
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getJobZones.pending, (state) => { 
                state.jobZonesData.data = [] 
                state.jobZonesData.loading = true; 
            })
            .addCase(getJobZones.fulfilled, (state, action) => { 
                state.jobZonesData.data = action.payload.data
                state.jobZonesData.loading = false; 
            }) 
            .addCase(getResult.pending, (state) => { 
                state.scores = {}; 
                state.scoreLoading = true;  
            })
            .addCase(getResult.fulfilled, (state, action) => { 
                state.scores = action.payload.data; 
                state.scoreLoading = false; 
                state.manually = action.payload.isManually 
            }) 
            .addCase(getResult.rejected , (state) => {
                state.scoreLoading = false; 
                state.scores = {}
            })
    }
});
export const { enterScores } = profilerSlice.actions;

export default profilerSlice.reducer;
