import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import useJwt from '../Auth/jwt/useJwt';

const AuthLayout = () => {
    const token = useJwt.getToken();

    if (token) {
        return <Navigate to="/welcome" />;
    }

    return <Outlet />;
};

export default AuthLayout;
