// ** Reducers Imports
import industries from './careerIndustries';
import questions from './questions';
import summary from './careerSummary';
import careers from './careers';
import profiler from './profiler'
const rootReducer = {
    questions,
    industries,
    summary,
    careers,
    profiler
};

export default rootReducer;
